import HeaderContato from "../components/Contato/Header/HeaderContato"
import Orcamento from "../components/Contato/Orcamento/Orcamento"
import Footer from "../components/Home/Footer/Footer";
import WhatsApp from '../components/WhatsApp';

function Contato() {
    return (
        <div className='contato'>
            <WhatsApp />
            <HeaderContato />
            <Orcamento />
            <div className="footer-section">
            <Footer 
                home=''
                SobreAHub=''
                carreira=''
                privacidadeECookies=''
                acessibilidade=''
                contato='ativo'
                />
            </div>
        </div>
    )
}

export default Contato