import Footer from "../components/Home/Footer/Footer";
import Consultoria from "../components/Servicos-consultoria/Consultoria/Consultoria"
import ConsultoriaCard from "../components/Servicos-consultoria/ConsultoriaCard/ConsultoriaCard"
import HeaderConsultoria from "../components/Servicos-consultoria/HeaderConsultoria/HeaderConsultoria"
import WhatsApp from '../components/WhatsApp';

function ServicoConsultoria() {
    return (
        <div className="servicoConsultoria">
            <WhatsApp />
            <HeaderConsultoria/>
            <Consultoria/>
            <ConsultoriaCard/>
            <div className="footer-section">
            <Footer 
                home=''
                SobreAHub=''
                carreira=''
                privacidadeECookies=''
                acessibilidade=''
                contato=''
                />
            </div>
        </div>
    )
}

export default ServicoConsultoria