import NoPageComponents from "../components/NoPage/NoPage"

function NoPage() {
    return (
        <div className="noPage">
            <NoPageComponents/>
        </div>
    )
}

export default NoPage