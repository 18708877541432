import './HeaderBpoBps.css'
import { Link } from "react-router-dom";

function HeaderBpoBps() {
    return (
<div className='header header-sobre-a-hub'>
            <Link onClick={window.scrollTo(0,0)} to='/'><img className="contato-header-img" src="/hub-logo-preta.png" alt='Imagem Hub Logo Preta'/></Link>
            <nav>
                <ul className="menu menu-preto">
                    <li><Link onClick={window.scrollTo(0,0)} to="/">HOME</Link></li>
                    <li><span>SERVIÇOS<i className="fa-solid fa-chevron-down"></i></span>
                        <ul>
                            <li><Link onClick={window.scrollTo(0,0)} to="/servicos-bpo-bps">BPO/BPS</Link></li>
                            <li><Link onClick={window.scrollTo(0,0)} to="/servicos-consultoria">CONSULTORIA</Link></li>
                            <li><Link onClick={window.scrollTo(0,0)} to="/servicos-on-demand">SERVIÇOS ON DEMAND</Link></li>
                        </ul>
                    </li>
                    <li><Link onClick={window.scrollTo(0,0)} to="/sobre-a-hub">SOBRE A HUB</Link></li>
                    <li><Link onClick={window.scrollTo(0,0)} to="/contato">CONTATO</Link></li>
                </ul>
            </nav>
            <div className="header-search" >
                <div className="header-icons">
                    <a href="https://www.linkedin.com/company/hub-sc" target="_blank" rel='noreferrer'><i className="fa-brands fa-linkedin"></i></a>
                    <a href="https://www.instagram.com/hubsc_oficial" target="_blank" rel='noreferrer'><i className="fa-brands fa-instagram"></i></a>
                    <a href="https://www.facebook.com/hubservicoscompartilhados" target="_blank" rel='noreferrer'><i className="fa-brands fa-square-facebook"></i></a>
                </div> 
            </div>
        </div>
    )
}

export default HeaderBpoBps