import Footer from "../components/Home/Footer/Footer";
import BpoBps from "../components/Servicos-bpo-bps/BpoBps/BpoBps"
import CardsBpo from "../components/Servicos-bpo-bps/CardsBpo/CardsBpo"
import HeaderBpoBps from "../components/Servicos-bpo-bps/HeaderBpoBps/HeaderBpoBps"
import WhatsApp from '../components/WhatsApp';

function ServicosBpoBps() {
    return (
        <div className="servicosBpoBps">
            <WhatsApp />
            <HeaderBpoBps/>
            <BpoBps/>
            <CardsBpo/>
            <div className="footer-section">
                <Footer />
            </div>
        </div>
    )
}

export default ServicosBpoBps