import './Parceiros.css'

function Parceiros() {
    return (
        <div className='parceiros'>
            <h1>Nossos Parceiros</h1>
            <img src='/parceiro-unescon.png' alt='icone Parceiro' width={220}/>
        </div>
    )
}

export default Parceiros