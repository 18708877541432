import AcessibilidadeArea from "../components/Acessibilidade/AcessibilidadeArea/AcessibilidadeArea"

import HeaderAcessibilidade from "../components/Acessibilidade/HeaderAcessibilidade/HeaderAcessibilidade"
import Footer from "../components/Home/Footer/Footer";
import WhatsApp from '../components/WhatsApp';

function Acessibilidade() {
    return (
        <div className="acessibilidade">
            <WhatsApp />
            <HeaderAcessibilidade/>
            <AcessibilidadeArea/>
            <div className="footer-section">
            <Footer 
                home=''
                SobreAHub=''
                carreira=''
                privacidadeECookies=''
                acessibilidade='ativo'
                contato=''
                />
            </div>
        </div>
    )
}

export default Acessibilidade