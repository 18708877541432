import './Objetivos.css'
import { Link } from 'react-router-dom'

function Objetivos() {
    return (
        <div className='objetivos'>
            <div className='objetivos-texto'>
                <h2>O objetivo de nosso time HUB é trazer <br/><span>insights relevantes</span> para o seu negócio.</h2>
                <Link onClick={window.scrollTo(0,0)} to="/contato">Entrar em contato agora</Link>
            </div>
            <div className='objetivos-img'>
            <img src='/hub-banner-light.webp' alt='Hub Banner' width={450}/>
            </div>
        </div>
    )
}

export default Objetivos