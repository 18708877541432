import Footer from "../components/Home/Footer/Footer";
import HeaderPrivacidade from "../components/Privacidade/HeaderPrivacidade/HeaderPrivacidade"
import PoliticaDePrivacidade from "../components/Privacidade/PoliticaDePrivacidade/PoliticaDePrivacidade"
import WhatsApp from '../components/WhatsApp';

function Privacidade() {
    return (
        <div className="privacidade">
            <WhatsApp />
            <HeaderPrivacidade/>
            <PoliticaDePrivacidade/>
            <div className="footer-section">
            <Footer 
                home=''
                SobreAHub=''
                carreira=''
                privacidadeECookies='ativo'
                acessibilidade=''
                contato=''
                />
            </div>
        </div>
    )
}

export default Privacidade