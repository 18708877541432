import CarouselSlider from './Carousel/Carousel'
import './MainSlider.css'



function MainSlider() {
    return (
        <div className='mainSlider' loading="eager">
          <CarouselSlider />
        </div>
    )
}

export default MainSlider