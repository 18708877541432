import './NoPage.css'

function NoPageComponents() {
    return (
        <div className='noPageComponents'>
            <div className='noPageComponents-area'>
                <img src='./404.png' alt='Error 404'/>
            </div>
        </div>
    )
}

export default NoPageComponents