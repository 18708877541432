import './style.css'

export default function WhatsApp() {
    return (
        <div className='whatsApp'>
            <div className='whatsApp-area'>
                <a href='https://api.whatsapp.com/send?phone=5511959102140' target='_blank' rel='noreferrer'><img src='./whatsapp-icone.png' alt='Icone do WhatsApp'/></a>
            </div>
        </div>
    )
}