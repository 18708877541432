import Footer from "../components/Home/Footer/Footer";
import HeaderSobreAHub from "../components/SobreAHub/HeaderSobreAHub/HeaderSobreAHub";
import OqueFazemos from "../components/SobreAHub/OqueFazemos/OqueFazemos";
import SobreGrafico from "../components/SobreAHub/SobreGrafico/SobreGrafico";
import SobreSobre from "../components/SobreAHub/SobreSobre/SobreSobre";
import WhatsApp from '../components/WhatsApp';

function SobreAHub() {
    return (
        <div className="headerSobreAHub">
            <WhatsApp />
            <HeaderSobreAHub />
            <div className="sobre-main-section">
                <SobreSobre />
            </div>
            <div className="sobre-second-section">
                <SobreGrafico />
            </div>
            <OqueFazemos />
            <div className="footer-section">
                <Footer
                    home=''
                    SobreAHub='ativo'
                    carreira=''
                    privacidadeECookies=''
                    acessibilidade=''
                    contato=''
                />
            </div>
        </div>
    )
}

export default SobreAHub