
import HeaderCarreira from "../components/Carreira/HeaderCarreira/HeaderCarreira";
import TrabalheConosco from "../components/Carreira/TrabalheConosco/TrabalheConosco";
import Footer from "../components/Home/Footer/Footer";
import WhatsApp from '../components/WhatsApp';

function Carreira() {
    return (
        <div className="carreira">
            <WhatsApp />
            <HeaderCarreira/>
            <div className="trabalheConosco-section">
            <TrabalheConosco/>
            </div>
            <div className="footer-section">
            <Footer 
                home=''
                SobreAHub=''
                carreira='ativo'
                privacidadeECookies=''
                acessibilidade=''
                contato=''
                />
            </div>
        </div>
    )
}

export default Carreira