import Footer from "../components/Home/Footer/Footer";
import CardOnDemad from "../components/ServicosOnDemand/CardOnDemad/CardOnDemad"

import HeaderOnDemand from "../components/ServicosOnDemand/HeaderOnDemand/HeaderOnDemand"
import SupportOnDemad from "../components/ServicosOnDemand/SupportOnDemad/SupportOnDemad"
import WhatsApp from '../components/WhatsApp';

function ServicosOnDemand() {
    return (
        <div className="servicosOnDemand">
            <WhatsApp />
            <HeaderOnDemand/>
            <SupportOnDemad/>
            <CardOnDemad/>
            <div className="footer-section">
            <Footer 
                home=''
                SobreAHub=''
                carreira=''
                privacidadeECookies=''
                acessibilidade=''
                contato=''
                />
            </div>
        </div>
    )
}

export default ServicosOnDemand